
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























































































































































































































































































































































































































































































.breadcrumb {
  font-size: 1.4rem;
  padding-right: 50%;
  margin-block: 0;
  padding-block: 50px;

  @media screen and (max-width: 767px) {
    padding-right: 0;
    padding-bottom: 0;
  }
}

.page-header-image {
  max-width: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 4rem;
  }
}

.page-header {
  margin-top: 1rem;

  &__title {
    display: flex;
    flex-flow: row wrap;
    width: auto;

    h1.main-title {
      display: block;

      @include mq(m) {
        display: inline-block;
      }
    }

    span.subtitle {
      display: inline-block;
      margin-left: 0;
      font-size: 1.4rem;
      @include mq(m) {
        margin-left: 1.5rem;
      }
    }

    .tips {
      margin-left: auto;
    }
  }

  &__actions {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
    margin-top: 1em;

    @media screen and (max-width: 767px) {
      width: 100%;
      display: block;
      gap: unset;
      align-items: stretch;
      margin-top: 3rem;

      a {
        width: 100%;
      }
    }
  }

  .btn-area {
    a {
      height: 62px;
    }

    @media screen and (max-width: 767px) {
      top: 20px;
      margin-bottom: 3rem;
      height: auto;

      a {
        width: 100%;
      }
    }

    .add-index {
      display: inline-flex;
    }
  }
}

.number {
  font-size: calc(0.2vw + 1.8rem);
}

.boxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3.5rem;
  margin-top: 3rem;
  align-items: self-start;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

.my-box {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.headline {
  margin: 1em 0;
  font-size: 1.6rem;
}

.boxes__item .separator {
  background-color: $c-gray-light;
}

.commune-meter-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
}

.commune-meter-info .meter-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  &__value {
    color: $c-orange;
    text-align: right;
  }

  &__label {
    &__info {
      color: $c-gray-info;
    }
  }
}

.cadran {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  margin-bottom: 1em;

  .number {
    font-size: 1.6rem;
  }
}

.meter-info__value__wrapper {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.toggle-label {
  font-size: 1.4rem;
}

.toggle-label:first-child {
  color: $c-gray-dark;
}
