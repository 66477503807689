
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























.tips {
  border-radius: 10px;
  width: 344px;
  padding: 30px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 28%);
  margin-bottom: 1em;
  position: relative;

  &__title {
    font-size: 1.8rem;
    color: $c-orange;
    font-weight: bolder;
    margin-bottom: 0.5em;
  }

  &__content {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}
