
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



































































































































































































































































































.adresse-switcher,
[class*='adresse-switcher--'] {
  display: flex;
  margin-left: auto;
  margin-right: calc(50vw - 42.4%);
  max-width: 50%;
  height: 100px;
  font-size: 0.8em;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;

  @include mq($until: s) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.adresse-switcher__meter,
.adresse-switcher__address {
  display: flex;
  align-items: center;
  padding: 30px;

  &__list__item:hover {
    cursor: pointer;
  }

  &__list__item:not(.active) {
    display: none;
  }

  &.deployed {
    position: relative;

    ul {
      position: absolute;
      top: 3rem;
      overflow-y: auto;
      max-height: 30rem;
      background: white;
      border-radius: 8px;
      padding: 20px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
    }

    li {
      display: block;
      color: #575756;
      transition: 0.2s;

      &:not(:last-child) {
        margin-bottom: 1em;
      }

      &:hover {
        background: $c-gray-lighter;
      }
    }

    .active-adress-header {
      display: none;
    }
  }
}

.adresse-switcher__address {
  background-color: $c-gray-lightest;
  width: 28em;

  &__title {
    color: $c-orange;
  }

  &__arrow {
    fill: $c-orange;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    max-width: 20px;
    max-height: 25px;
    margin-right: 1.3rem;
    fill: $c-orange;
  }

  @include mq(s, l) {
    margin-bottom: 0;
  }

  @include mq(xl) {
    margin-bottom: 0;
  }

  &__search {
    width: 100%;
    margin-bottom: 2rem;
    @include mq(m) {
      max-width: 41.9rem;
      margin-bottom: unset;
    }

    .selector-input-wrapper {
      position: relative;

      &:after {
        content: url('../../../assets/svg/commune/search.svg');
        width: 1.9rem;
        height: 2rem;
        position: absolute;
        right: 2rem;
        top: calc(50% - 1rem);
        transition: opacity 0.3s ease-in-out;
      }

      &:focus-within:after {
        opacity: 0.5;
      }
    }

    input.search {
      width: 100%;
      border: 0;
      background-color: $c-gray-lightest;
      border-radius: 0.5rem;
      padding: 1rem 2rem;

      &:focus {
        border: 1px solid $c-gray-darker;
        background-color: $c-white;
      }
    }
  }
}

.adresse-switcher__meter {
  color: $c-white;
  background-color: $c-orange;
  width: 28em;

  &__arrow {
    fill: $c-white;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }
}

.active-adress-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
