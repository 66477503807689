
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


















.my-box {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 35px, xxl: 50px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 20px, xxl: 40px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 35px, xxl: 50px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 20px, xxl: 40px));

  display: flex;
  flex-direction: column;
  border: 1px solid $c-gray-light;
  border-bottom: 4px solid $c-yellow;

  &.no-border {
    border-bottom: 1px solid $c-gray-light;
  }
}

.my-box__title {
  // prettier-ignore
  @include fluid(font-size, (xxs: 18px, xxl: 24px));
  font-family: $ff-alt;
  font-weight: 400;
  margin-bottom: 1em;
}

.my-box__footer {
  margin-top: auto;
}

.my-box__separator {
  height: 1px;
  margin-top: 0;
  background: $c-gray-light;
  border: 0;
}

.commune-index .my-box__footer {
  text-align: center;

  a {
    min-width: 200px;
  }
}
