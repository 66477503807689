
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




































.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  .input {
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $c-gray-lighter;
  border-radius: 34px;
  transition: 0.4s;

  &::before {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 4px;
    width: 26px;
    height: 26px;
    background-color: $c-white;
    border-radius: 50%;
    transition: 0.4s;
  }
}

.input:disabled + .slider {
  opacity: 0.2;
  cursor: default;
}

.input:checked + .slider {
  background-color: $c-main-primary;

  &.green {
    background-color: $c-green;
  }
}

.input:focus + .slider {
  box-shadow: 0 0 1px $c-gray-lighter;
}

.input:checked + .slider::before {
  transform: translateX(26px);
}
